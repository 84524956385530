.App {
  font-family: 'Euclid Circular A', sans-serif;                                  
  line-height: 1.6;
  margin: 0;
  padding: 0;
  /* background: rgba(0, 0, 0, 0.85); */
}

.mainSection-mobile {
  /* color: white; */
  padding: 36px 0;
}

.mainSection-desktop {
  /* color: white; */
  padding: 72px 0;
}

.backgroundSection {
  display: flex;
  align-items: center;
}

.servicesTitle-desktop {    
  font-size: 42px;
  margin-bottom: 12px;
}

.servicesTitle-mobile {    
  font-size: 34px !important;
  margin-bottom: 12px;
}

.copilotIconInSummary {
  margin: 0 24px 4px 6px;
  width: 45px;
}

.servicesSubtitle {
  font-size: 18px;
  margin-bottom: 12px;
}

.backgroundImage {
  width: 100%;
}

.whyusSection {
  background-image: -webkit-gradient(linear,left top,left bottom,from(#e0e0bc),to(#bce0d0));
  background-image: linear-gradient(180deg,#e0e0bc,#bce0d0);
  padding: 72px 0;
}

.contactSection {
  background-color: #f0f6fc;
  padding: 72px 0;
}

.techSection {
  background-color: #f0f6fc;
  padding: 72px 0;
}

.visionRow {
  color: #5da9f5;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 12px;
}

.techSectionTitle {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 24px;
}

.buttonLeft {
  margin-left: 12px;
}

.techBox {
  background-color: white;
  border: 1px solid black;
  padding: 24px !important;
  border-radius: 5%;
  margin-bottom: 24px;
}

.useCaseRow {
  color: #949426;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 12px;
}

.useCaseTitleRow {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.useCaseSubTitle {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.serviceBox {
  text-align: center;
}

.serviceBoxTitle {
  font-size: 48px;
  font-weight: bold;
}

.learnmoreText {
  margin-top: 12px;
}

.learnmoreButton {
  color: #65d088;
  margin-top: 12px;
}

.learnmoreButton:hover {
  cursor: pointer;
  text-decoration: underline;
}

.copilotSection {
  padding: 72px 0;
  background-color: rgb(248,249,250);
  background-size: cover;
  background-repeat: no-repeat;
}

.copilotContainer {
  display: flex;
  align-items: center;
}

.chatbotScreenshot {
  width: 100%;
}

.copilotButton {
  background-color: #65d088 !important;
  border: none !important;
  font-size: 20px !important;
  font-weight: 400 !important;
}

.copilotButton:hover {
  background-color: #74866b !important;
}

.copilotIcon {
  margin: 0 24px 4px 6px;
  width: 30px;
}

.arrowIcon {
  width: 64px;
}

.howitworksSection {
  background-color: #ddebe4;
  padding: 72px 0;
}

.stepText {
  margin: 0 0 12px 0;
}

.stepTitle {
  font-size: 20px;
  color: #65d088;
  font-weight: bold;
}

.stepNumber {
  font-weight: bold;
  color: #65d088;
  background: white;
  width: 34px;
  display: block;
  text-align: center;
  border: 1px solid black;
  border-radius: 10px;
}

.stepDetails {
  margin-bottom: 24px;
}

.stepSummary {
  margin: 12px 0;
  font-size: 20px;
}

.howitworksButton {
  margin-top: 24px;
}

.pricingSection {
  background-color: #f0f6fc;
  text-align: center;
  padding: 72px 0 36px 0;
}

.pricingSubSection {    
  border: 1px solid black;
  border-radius: 10px;
  padding: 36px !important;
  margin-bottom: 36px;
  background-color: white;
  position: relative;
}

.pricingSubSection-mobile {
  position: relative;
}

.pricingTitle {
  color: #65d088;
  font-size: 36px;
}

.pricingPrice {
  font-size: 24px;
}

.checkIcon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.pricingButton {
  position: absolute;
  bottom: 36px;
  left: calc(50% - 104px);
  padding: 12px 36px !important;
}

.userFeedback {
  display: flex;
  text-align: left;
  margin-bottom: 24px;
}

.quoteText-desktop {
  margin-top: 28px;
}

.quoteIconContainer-mobile {
  background: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 4px;
  position: absolute;
  left: -16px;
  top: -16px;
}

.quoteIcon-desktop {
  width: 80px;
}

.quoteIcon-mobile {
  width: 24px;
}

.username {
  text-align: right;
}


.customServices {
  margin-top: 36px;
}

.whoareweSection {
  text-align: center;
  padding: 72px 0 36px 0;
}

.whoareweRow {
  font-size: 30px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 48px;
}

.usText {
  font-size: 18px;
  margin: 12px 0 36px 0;
}

.cpaIcon {
  height: 84px;
}

.startupIcon {
  height: 60px;
  margin-bottom: 16px;
  margin-top: 8px;
}

.googleIcon {
  height: 64px;
  margin: 10px 6px;
}

.airbnbIcon {
  height: 64px;
  margin: 10px 6px;
}

.consultationSection {
  /* border-top-left-radius: 50px;
  border-top-right-radius: 50px; */
  padding: 72px 0 36px 0;
  background-image: -webkit-gradient(linear,left top,left bottom,to(#e0e0bc),from(#bce0d0));
  background-image: linear-gradient(180deg,#bce0d0,#e0e0bc);
}

.scanText {
  margin: 24px 0 36px 0;
}

.iosQrCode {
  max-width: 100px;
  max-height: 100px;
  margin-left: 24px;
}

.codeCenterAligned {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.getstartedTitle {
  text-align: center;
  font-size: 24px;
  display: inline-block;
}

.comingSoon {
  color: #949426;
  font-weight: bold;
  margin-left: 6px;
  border: 1px solid #949426;
  padding: 4px;
  font-size: 12px;
  vertical-align: middle;
}

.waitlistForm {
  border: 1px solid black;
  border-radius: 10px;
  padding: 36px !important;
  background-color: white;
  margin-bottom: 36px;
}

.contactTitle {
  text-align: center;
  font-size: 30px;
}

.contactText {
  font-size: 22px;
}

.inputRow {
  margin-top: 12px !important;
}

.inputRowCenter {
  justify-content: center;
}

.submit-button {
  margin-top: 24px;
  text-align: center;
}

.submitButton {
  background-color: #65d088 !important;
  border: none !important;
  font-size: 20px !important;
  font-weight: 400 !important;
}

.submitButton:hover {
  background-color: #74866b !important;
}

.lighterButton {
  background-color: white !important;
  color: black !important;
  border: 1px solid gray !important;
  font-size: 20px !important;
  font-weight: 400 !important;
}

.lighterButton:hover {
  background-color: lightgray !important;
}

.error-msg {
  color: red;
}

.successMsg {
  color: green;
}

footer {
  margin: 36px 0;
  text-align: center;
}

.email-link {
  color: #007bff;
  text-decoration: underline;
}

.contactTitle {
  font-size: 30px;
  text-align: center; 
  margin: 36px 0 12px 0;
}
