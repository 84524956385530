.narbarOverride {
  background-color: white !important;
}

.brand {
  display: flex;
  align-items: center;
}

.companyTitle {
  display: flex;
  align-items: center;
  font-size: 24px;
}

.companyTitle:hover {
  cursor: pointer; 
}

.logo {
  height: 36px;
  width: 36px;    
  margin-bottom: 4px;
  margin-right: 6px;
}

.navLink {
  color: black !important;
}

.navLink:hover {
  text-decoration: underline;
}

.betaNav {
  color: #65d088 !important;
  border: 1px solid #65d088;
  border-radius: 5px;
  padding: 2px;
  font-size: 12px;
  font-weight: 500;
}

.consultation {
  background-color: #65d088 !important;
  border: none !important;
  font-size: 20px !important;
  font-weight: 400 !important;
}

.consultation:hover {
  background-color: #74866b !important;
}