body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "ColfaxAI";
  src: url(https://cdn.openai.com/API/fonts/ColfaxAIRegular.woff2)
      format("woff2"),
    url(https://cdn.openai.com/API/fonts/ColfaxAIRegular.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ColfaxAI";
  src: url(https://cdn.openai.com/API/fonts/ColfaxAIBold.woff2) format("woff2"),
    url(https://cdn.openai.com/API/fonts/ColfaxAIBold.woff) format("woff");
  font-weight: bold;
  font-style: normal;
}
